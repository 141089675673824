// events on load
var $jq = jQuery.noConflict();
//$jq(window).ready(function() {
(function ($) {
    // fixedHeaderIndent();
    openBtn(document.querySelectorAll(".js-menuBtn"), "menu-is-open");
    openBtn(document.querySelectorAll(".js-menuCatBtn"), "menuCat-is-open");
    openBtn(document.querySelectorAll(".js-filterBtn"), "filter-is-open");
    openDropdown();
    micromodalInit();
    headerSearch();
    showPass();
    houdiniDropdown();
    dropCat();
    indexSlider();
    blogSlider();
    backToTop();
    smoothScroll();
    initWaypoint();
    catalogSorting();
    viewTogglers();
    catFilterRange();
    testimonialsSlider();
    modalCartCount();
    initTabby();
    testimonialPost();
    testimonialReply();
    itemOptions();
    startCountdown();
    itemPageSlider();
    fancyInit();
    fixedHeader();
    accountMenuDropdown();
    accountAccordion();
    accordionInit();
    togglerShowInit();
    initializeMap();
})(jQuery);

// events on scroll
var timeoutScroll;
window.addEventListener(
    "scroll",
    function () {
        fixedHeader();
        closeBtnAction(0, 1200, "menuCat-is-open");
    },
    false
);

// events on resize
window.addEventListener(
    "resize",
    function () {
        closeBtnAction(0, 1200, "menuCat-is-open");
    },
    false
);

// events on click
$jq(document).on("click", function (e) {
    dropdownClickContentClose(e);
    closeMenuClickOut(
        e,
        $jq(".mMenu, .js-menuBtn"),
        $jq(".js-menuBtn"),
        "menu-is-open"
    );
    closeMenuClickOut(
        e,
        $jq(".mMenuCat, .js-menuCatBtn"),
        $jq(".js-menuCatBtn"),
        "menuCat-is-open"
    );
    closeMenuClickOut(
        e,
        $jq(".catalogFilters, .catalogSelectedOptions, .js-filterBtn"),
        $jq(".js-filterBtn"),
        "filter-is-open"
    );
});