function fixedHeader() {

	var body = document.querySelector("body");
	var siteHeader = document.querySelector(".siteHeader");
	var mMenuCatHeught = ($jq(".mMenuCat").length > 0) ? document.querySelector(".mMenuCat").offsetHeight : 0;
	var fixedBodyClass = "header-is-fixed";


	if (body.classList.contains(fixedBodyClass) || ($jq(".siteHeader").length < 1)) {
		return false;
	} else {
		var siteHeaderHeight = siteHeader.offsetHeight + siteHeader.offsetTop;
	}
	var fixedStartPosition = siteHeaderHeight + mMenuCatHeught;
	body.style.setProperty("--headerFixedIndent", siteHeaderHeight + "px");
	if (window.innerWidth > 1200) {
		fixedHeaderDecision(fixedStartPosition);
	}

	function fixedHeaderDecision(fixedStartPosition) {
        $jq(window).scroll(function() {
			var scrollPosition = window.pageYOffset;
			if (scrollPosition >= fixedStartPosition) {
				body.classList.add(fixedBodyClass);
			} else {
				body.classList.remove(fixedBodyClass);
			}
		});
	}
}
