function backToTop() {
	var $jqbackToTop = $jq(".backToTop");
	var $jqwindowHeight = $jq(window).height();
	$jqbackToTop.hide();


	$jq(window).on('scroll', function() {
		if ($jq(this).scrollTop() > $jqwindowHeight) {
			$jqbackToTop.fadeIn();
		} else {
			$jqbackToTop.fadeOut();
		}
	});

	$jqbackToTop.on('click', function(e) {
		$jq("html, body").animate({scrollTop: 0}, 500);
	})
}