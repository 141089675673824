function showPass() {
	$jq(".js--showPass").hover(
		function() {
			$jq(this)
				.siblings($jq(".js--pass"))
				.attr("type", "text");
		},
		function() {
			$jq(this)
				.siblings($jq(".js--pass"))
				.attr("type", "password");
		}
	);

	$jq(".js--showPass").on("ckick", function() {
		event.preventDefault();
	});
}