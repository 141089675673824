function modalCartCount() {
	var itemCartMax = 100;
	$jq('.minus-btn').on('click', function(e) {
		e.preventDefault();
		var $jqthis = $jq(this);
		var $jqinput = $jqthis.closest('div').find('input');
		var value = parseInt($jqinput.val());
		
		if (value > 1) {
			value = value - 1;
		} else {
			value = 0;
		}
		
		$jqinput.val(value);
		
	});
	
	$jq('.plus-btn').on('click', function(e) {
		e.preventDefault();
		var $jqthis = $jq(this);
		var $jqinput = $jqthis.closest('div').find('input');
		var value = parseInt($jqinput.val());
		
		if (value < itemCartMax) {
			value = value + 1;
		} else {
			value =itemCartMax;
		}
		
		$jqinput.val(value);
	});
}