function itemPageSlider() {
	$jq(".itemPageSLider").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		asNavFor: ".itemPageSLiderNav",
		infinite: false,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					arrows: true
				}
			},
			{
				breakpoint: 767,
				settings: {
					arrows: false,
					dots: true
				}
			}
		]
	});
	$jq(".itemPageSLiderNav").slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: ".itemPageSLider",
		infinite: false,
		arrows: false,
		dots: false,
		focusOnSelect: true,
		vertical: true
	});
}