function indexSlider() {
	if ($jq(".indexSlider").length > 0) {
		var slider = tns({
			container: ".indexSlider .wrapper",
			items: 1,
			slideBy: "page",
			controls: false,
			nav: false,
			autoplay: true,
			autoplayHoverPause: true,
			autoplayButtonOutput: false,
			loop: true,
			mouseDrag: true,
			arrowKeys: true,
			speed: 400
		});
	}
}