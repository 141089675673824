function openDropdown() {
	var dropdownFirstTrigger = $jq(".js-dropdownFirstTrigger");
	var dropdownSecondTrigger = $jq(".js-dropdownSecondTrigger");
	var dropdownHoverTrigger = $jq(".js-dropdownHoverTrigger");
	var dropdownContainer = $jq(".js-dropdownContainer");
	var dropdownContent = $jq(".js-dropdownContent");

	dropdownFirstTrigger.on("click", function() {
		var dropdownContainer = $jq(this).parent(".js-dropdownContainer");
		var dropdownFirstTrigger = $jq(this);
		dropdownAction(dropdownContainer);
	});

	dropdownSecondTrigger.on("click", function() {
		var dropdownContainer = $jq(this).closest(".js-dropdownContainer");
		dropdownAction(dropdownContainer);
	});

	dropdownHoverTrigger.hover(function() {
		dropdownAction($jq(this));
	});

	function dropdownAction(dropdownContainer) {
		if (!dropdownContainer.hasClass("js-active")) {
			setTimeout(fun, 1);
			function fun() {
				dropdownContainer.addClass("js-active");
			}
			dropdownContainer.addClass("js-transitioning");
		} else {
			dropdownContainer.removeClass("js-active");
		}
	}

	dropdownContent.on("transitionend", function(e) {
		if (
			!$jq(this)
				.parent(".js-dropdownContainer")
				.hasClass("js-active")
		) {
			$jq(this)
				.parent(".js-dropdownContainer")
				.removeClass("js-transitioning");
		}
	});
}

function dropdownClickContentClose(e) {
	var dropdownContainer = $jq(".js-dropdownContainer");
	if (
		!dropdownContainer.is(e.target) &&
		dropdownContainer.has(e.target).length === 0
	) {
		dropdownContainer.removeClass("js-active");
		e.stopPropagation();
	}
}