function viewTogglers() {
	var toggledClass = "js-triggered";
	var cardToggle = "catalogCardViewTogglers__view--card";
	var listToggle = "catalogCardViewTogglers__view--list";
	var viewCard = "viewCard";
	var viewList = "viewList";
	var viewContainer = $jq("body");

	$jq(".js-viewToggler").on("click", function() {
		$jq(".js-viewToggler").removeClass(toggledClass);
		$jq(this).addClass(toggledClass);
		if ($jq(this).hasClass(cardToggle)) {
			viewContainer.removeClass(viewList).addClass(viewCard);
		} else if ($jq(this).hasClass(listToggle)) {
			viewContainer.removeClass(viewCard).addClass(viewList);
		}
	});
}