function dropCat() {
	drop.init({
		callback: function(toggle) {
			// getCatHeight();
			dropCatOverlay();
		}
	});
}

function dropCatOverlay() {
	if (window.innerWidth > 1200) {
		$jq("body").addClass("js-overlay--subBox");
	} else {
		return false;
	}
}