function micromodalInit() {
	MicroModal.init();

	// MicroModal.show('modalCityStart');

	var closeFormTrigger = $jq(".closeFormTrigger");
	var formBody = $jq(".modal");

	closeFormTrigger.on("click", function(event) {
		event.preventDefault();
		formBody.removeClass("is-open");
	});
}