function catFilterRange() {
	var keypressSlider = document.getElementById("catFilterRange");
	
	if (keypressSlider) {
		var input0 = document.getElementById("catFilterRangeInput0");
		var input1 = document.getElementById("catFilterRangeInput1");
		var start0 = input0.value;
		var start1 = input1.value;
		var rangeMin = parseInt(input0.getAttribute("data-price-min"));
		var rangeMax = parseInt(input1.getAttribute("data-price-max"));
		var inputs = [input0, input1];

		noUiSlider.create(keypressSlider, {
			start: [start0, start1],
			connect: true,
			behaviour: "drag-tap",
			tooltips: false,
			format: wNumb({ decimals: 0 }),
			range: {
				min: rangeMin,
				max: rangeMax
			}
		});

		keypressSlider.noUiSlider.on("update", function(values, handle) {
			inputs[handle].value = values[handle];
		});

		function setSliderHandle(i, value) {
			var r = [null, null];
			r[i] = value;
			keypressSlider.noUiSlider.set(r);
		}

		inputs.forEach(function(input, handle) {
			input.addEventListener("change", function() {
				setSliderHandle(handle, this.value);
			});

			input.addEventListener("keydown", function(e) {
				var values = keypressSlider.noUiSlider.get();
				var value = Number(values[handle]);
				var steps = keypressSlider.noUiSlider.steps();
				var step = steps[handle];
				var position;
				switch (e.which) {
					case 13:
						setSliderHandle(handle, this.value);
						break;
					case 38:
						position = step[1];
						if (position === false) {
							position = 1;
						}
						if (position !== null) {
							setSliderHandle(handle, value + position);
						}
						break;
					case 40:
						position = step[0];
						if (position === false) {
							position = 1;
						}
						if (position !== null) {
							setSliderHandle(handle, value - position);
						}
						break;
				}
			});
		});
	}
}