function initWaypoint() {
	$jq(".catDirection").each(function(index, element) {
		$jq(element).waypoint({
			handler: function(direction) {
				if (direction === "down") {
					$jq(".stickyBar li a").removeClass("active");
					$jq(".stickyBar li:eq(" + index + ") a").toggleClass("active");
				}
			},
			offset: "20%"
		});
		$jq(element).waypoint({
			handler: function(direction) {
				if (direction === "up") {
					$jq(".stickyBar li a").removeClass("active");
					$jq(".stickyBar li:eq(" + index + ") a").toggleClass("active");
				}
			},
			offset: "5%"
		});
	});
}