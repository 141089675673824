function blogSlider() {
	if ($jq(".blogTeaser__slidesWrapper").length > 0) {
		var slider = tns({
			container: ".blogTeaser__slidesWrapper",
			fixedWidth: 270,
			speed: 400,
			slideBy: "page",
			controls: false,
			nav: false,
			autoplay: false,
			loop: true,
			mouseDrag: true,
			arrowKeys: true,
			gutter: 15
		});
	}
}