function testimonialsSlider() {
	if ($jq(".productTestimonials__wrapper").length > 0) {
		var slider = tns({
			container: ".productTestimonials__wrapper",
			items: 2,
			responsive: {
				768: {
					items: 3
				},
				992: {
					items: 4
				}
			},
			speed: 400,
			slideBy: "page",
			controls: false,
			nav: false,
			autoplay: false,
			loop: false,
			mouseDrag: true,
			arrowKeys: true,
			gutter: 0
		});
	}
}