function startCountdown() {
	$jq('[data-countdown]').each(function() {
		var $jqthis = $jq(this), finalDate = $jq(this).data('countdown');
		$jqthis.countdown(finalDate, function(event) {
			$jqthis.html(event.strftime(''
				+'<div class="itemMainSales__section"><b>%d</b><span>дней</span></div>'
				+'<div class="itemMainSales__section"><b>%H</b><span>час</span></div>'
				+'<div class="itemMainSales__section"><b>%M</b><span>мин</span></div>'
				+'<div class="itemMainSales__section itemMainSales__section--accent"><b>%S</b><span>сек</span></div>'));
		});
	});
}