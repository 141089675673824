function accountMenuDropdown() {
	var trigger = $jq(".js-sidebarMenu-trigger");
	var target = $jq(".js-sidebarMenu-container a");
	var activeClass = "is-open";
	trigger.on("click", function() {
		$jq(this).toggleClass(activeClass);
	});
	target.on("click", function() {
		target.removeClass(activeClass);
	});
}