function itemOptions() {
	var trigger = $jq(".optionsTrigger");
	var triggerTextHolder = $jq(".optionsTextHolder");
	var triggerTextOn = $jq(".optionsTextHolder").attr("data-on");
	var triggerTextOff = $jq(".optionsTextHolder").attr("data-off");
	var container = $jq(".itemMainOptions");
	var current = $jq(".itemMainOptions").find(".current");
	var activeClass = "active";
	var item = $jq(".itemMainOptions").find(".item");
	var itemImg = $jq(".itemMainOptions").find(".itemImg");

	trigger.on("click", function() {
		if ($jq(this).hasClass(activeClass)) {
			container.removeClass(activeClass);
			trigger.removeClass(activeClass);
			triggerTextHolder.text(triggerTextOn);
		}
		else {
			container.addClass(activeClass);
			trigger.addClass(activeClass);
			triggerTextHolder.text(triggerTextOff);
		}
	})

	item.on("click", function() {
		var thisImg = $jq(this).find(".itemImg");
		var thisTitle = $jq(this).find(".itemTitle").text();

		itemImg.removeClass(activeClass);
		thisImg.addClass(activeClass);
		current.text(thisTitle);
	})

	item.on("mouseover", function() {
		var thisImg = $jq(this).find(".itemImg");
		var thisTitle = $jq(this).find(".itemTitle").text();

		itemImg.removeClass(activeClass);
		thisImg.addClass(activeClass);
		current.text(thisTitle);
	})
}