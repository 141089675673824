function openBtn(btnTrigger, bodyClass) {
	for (var i = 0; i < btnTrigger.length; i++) {
		btnTrigger[i].addEventListener("click", function() {
			if (!body.classList.contains(bodyClass)) {
				openBtnAction(bodyClass);
				for (var i = 0; i < btnTrigger.length; i++) {
					btnTrigger[i].classList.add("js-triggered");
				}
			} else {
				closeBtnAction(windowY, 0, bodyClass);
				for (var i = 0; i < btnTrigger.length; i++) {
					btnTrigger[i].classList.remove("js-triggered");
				}
			}
		});
	}
}

function openBtnAction(bodyClass) {
	windowY = window.scrollY;
	body.classList.add(bodyClass);
	return windowY;
}

function closeBtnAction(windowY, minimumWidth, bodyClass) {
	if (body.classList.contains(bodyClass) && window.innerWidth > minimumWidth) {
		body.classList.remove(bodyClass);
	}
	if (windowY > 0) {
		setTimeout(window.scrollTo(0, windowY), 500);
	}
}


function closeMenuClickOut(e, el, trigger, bodyClass) {
	if (!el.is(e.target) && el.has(e.target).length === 0) {
		body.classList.remove(bodyClass);
		trigger.removeClass("js-triggered");
		e.stopPropagation();
	}
}