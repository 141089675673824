function initializeMap() {
	if (document.getElementById("google-map")) {
		var e = new google.maps.LatLng(45.273127, -66.060643),
			t = {
				zoom: 14,
				center: e,
				panControl: 1,
				zoomControl: 1,
				mapTypeControl: 1,
				scaleControl: 1,
				streetViewControl: 1,
				overviewMapControl: 1,
				scrollwheel: 1,
				mapTypeId: google.maps.MapTypeId.ROADMAP
			},
			l = new google.maps.Map(document.getElementById("google-map"), t);
		var a = new google.maps.Marker({
			position: e,
			map: l,
			title: "Enterprise Saint John"
		});
	} else {
		return false
	}

}