function catalogSorting() {
	var activeClass = "js-active";
	$jq(".js-sortingInput").on("click", function() {
		var sortingData = $jq(this)
			.find("a")
			.attr("data-filter-criterion");
		$jq(".js-sortingOutput").attr("data-filter-criterion", sortingData);
		$jq(".js-sortingInput").removeClass(activeClass);
		$jq(this).addClass(activeClass);
		$jq(".catalogSorting").removeClass(activeClass);
	});
}